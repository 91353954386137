import { ClientSearchSDK } from '@wix/client-search-sdk';
import { createSettingsClient } from '@wix/search-settings-client';
import { ControllerParams } from '@wix/yoshi-flow-editor';

import { IExtendedControllerParams } from './platform.types';
import { createSearchLocation } from './location';
import { Spec } from './specs';
import { reportError } from './errors';

export const extendControllerParams = (
  params: ControllerParams,
): IExtendedControllerParams => {
  const { flowAPI, controllerConfig } = params;
  const { wixCodeApi } = controllerConfig;
  const { environment, experiments, httpClient, errorMonitor } = flowAPI;
  const { isViewer, language } = environment;
  const siteBaseUrl = wixCodeApi.location.baseUrl;

  const settingsClient = createSettingsClient({
    httpClient,
    defaultImageResizeModeFit: experiments.enabled(
      Spec.DefaultImageResizeModeFit,
    ),
  });

  const getAppSettings = async () => {
    try {
      const appSettings = isViewer
        ? await settingsClient.getPublished()
        : await settingsClient.getSaved();

      return appSettings;
    } catch (error) {
      reportError(errorMonitor, error);
      return settingsClient.getDefault();
    }
  };

  const searchLocation = createSearchLocation(wixCodeApi);

  const searchSDK = new ClientSearchSDK({
    httpClient,
    siteBaseUrl,
    language,
    timeout: 25000,
  });

  return {
    ...controllerConfig,
    getAppSettings,
    searchLocation,
    searchSDK,
    flowAPI,
  };
};
